@import "styles/colors";
@import "styles/buttons";
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');

html {
    background: url('/assets/img/vivid-background_.jpg');  
    background-size: cover;
    padding: 2rem;
    margin: 0;
}
body {
    // background: -webkit-linear-gradient(to top, #4f2c64, #203A43, #0F2027);  
    // background: linear-gradient(to top, #412c64, #39a2c5,  #399dc5, #154f70); 
    margin: 0;
    padding: 0;

    color: white;
}

body {
    font-family: 'Alata', 'Helvetica', sans-serif;
    height: 100vh;
}

.full-height {
    height: 100%;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.row { 
    display: flex;
    gap: 0.5rem;
    align-items: center;
}