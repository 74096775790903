.button {
    padding: 1em 1.5em;
    border: 1px solid white;
    border-radius: 0.2rem;
    
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    font-weight: 800;

    cursor: pointer;
}